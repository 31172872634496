// Import our custom CSS
import "../scss/404.scss";

let lFollowX = 0,
    lFollowY = 0,
    x = 0,
    y = 0,
    friction = 1 / 30;

function css(element, style) {
    for (const property in style)
        element.style[property] = style[property];
}

function animate() {
    x += (lFollowX - x) * friction;
    y += (lFollowY - y) * friction;

    let translate = "translate(" + x + "px, " + y + "px) scale(1.1)";

    css(document.querySelector("img"), {
        "-webit-transform": translate,
        "-moz-transform": translate,
        "transform": translate
    });

    window.requestAnimationFrame(animate);
}

const bodyElement = document.querySelector("body")

bodyElement.addEventListener("mousemove", (e) => {
    let lMouseX = Math.max(-100, Math.min(100, document.querySelector("body").offsetWidth / 2 - e.offsetX))
    let lMouseY = Math.max(-100, Math.min(100, document.querySelector("body").offsetHeight / 2 - e.offsetY))
    lFollowX = (20 * lMouseX) / 100 // 100 : 12 = lMouseX : lFollow
    lFollowY = (10 * lMouseY) / 100
});

animate();
